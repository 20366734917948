import React from "react";
import { styled } from "styled-components";
import { useTranslation } from "react-i18next";

const MentionsLegales = () => {
	const { t } = useTranslation();

	return (
		<MainContainer className="mentions-legales">
			<h1 className="title-mentions">{t("footer.legal")}</h1>
			<SectionContain>
				<article className="section-mentions">
					<p>{t("footer.legal_page.first")}</p>
				</article>
				<article className="section-mentions">
					<h2 className="subtitle-mentions">
						{t("footer.legal_page.title_two")}
					</h2>
					<p>{t("footer.legal_page.two")}</p>
				</article>

				<article className="section-mentions">
					<h2 className="subtitle-mentions">
						{t("footer.legal_page.title_three")}
					</h2>
					<p>{t("footer.legal_page.three")}</p>
				</article>

				<article className="section-mentions">
					<h2 className="subtitle-mentions">
						{t("footer.legal_page.title_four")}
					</h2>
					<p>{t("footer.legal_page.four")}</p>
				</article>

				<article className="section-mentions">
					<h2 className="subtitle-mentions">
						{t("footer.legal_page.title_five")}
					</h2>
					<ul>
						<li>{t("footer.legal_page.by_phone")} : 07.45.08.29.97</li>
						<li>
							{t("footer.legal_page.by_email")} : johane.omiscar@gmail.com
						</li>
						<li>
							{t("footer.legal_page.by_mail")} : 9 rue des Colonnes, 75002 PARIS
						</li>
					</ul>
				</article>
			</SectionContain>
		</MainContainer>
	);
};

export default MentionsLegales;

const MainContainer = styled.main`
	padding: 6rem 0 5rem 0;
`;

const SectionContain = styled.section`
	width: 80%;
	margin-left: 3rem;

	p {
		font-size: 1.1rem;
	}

	h2 {
		font-size: 2rem;
	}

	li {
		color: #fafafa;
		list-style-type: disc;
		font-size: 1.1rem;
		font-family: "Gowun Batang", serif;
	}
`;
