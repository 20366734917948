import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useRoutes } from "react-router-dom";
import { motion } from "framer-motion";
import { fadeInBottomVariant } from "../utils/variants";
import { RxCross2 } from "react-icons/rx";
import { AiOutlineMenu } from "react-icons/ai";
import { FaArrowRightArrowLeft } from "react-icons/fa6";
// import { FaHandPointDown } from "react-icons/fa";
import Logo from "../assets/logo.png";
import LogoWhite from "../assets/logo-white.png";

import { styled } from "styled-components";

const Header = () => {
	// responsive settings
	const [mobileBtn, setMobileBtn] = useState(false);
	// settings translation btn
	const [open, setOpen] = useState(false);
	const [selectedLanguage, setSelectedLanguage] = useState("fr");
	//change background header when scrolling
	const [background, setBackground] = useState(false);
	// focus on the FR btn
	const linkRef = useRef(null);
	const linkRefFr = useRef(null);
	const linkRefEn = useRef(null);
	// translation settings
	const { t, i18n } = useTranslation();

	const changeBackground = () => {
		if (window.scrollY >= 60) {
			setBackground(true);
		} else {
			setBackground(false);
		}
	};

	window.addEventListener("scroll", changeBackground);

	const handleKeyDown = (e, ref) => {
		if (e.key === "Enter") {
			// setOpen(true);
			ref.current.click();
		}
	};

	const changeLanguage = (lang) => {
		return () => {
			i18n.changeLanguage(lang);
		};
	};

	return (
		<HeaderNav className={background ? "header_bg" : null}>
			<Nav state={mobileBtn ? 1 : 0}>
				<DivLogo>
					<ul>
						{window.location.pathname === "/works" ||
						window.location.pathname === "/services" ||
						window.location.pathname === "/about" ? (
							<NavLink to="/">
								<Image
									src={LogoWhite}
									alt="WebSculpt Creations logo développeur"
								/>
							</NavLink>
						) : (
							<NavLink to="/">
								<Image src={Logo} alt="WebSculpt Creations logo développeur" />
							</NavLink>
						)}
					</ul>
				</DivLogo>
				<Div className={`links ${mobileBtn ? "show" : ""}`}>
					<DivList>
						<Links>
							<NavLink to="/works">{t("nav.realisations")}</NavLink>
						</Links>
						<Links>
							<NavLink to="/services">{t("nav.services")}</NavLink>
						</Links>
						<Links>
							<NavLink to="/about">{t("nav.about")}</NavLink>
						</Links>
						<Links>
							<NavLink to="/contact">{t("nav.contact")}</NavLink>
						</Links>
						<div className="language">
							<Links
								tabIndex={0}
								ref={linkRefFr}
								onKeyDown={(e) => {
									handleKeyDown(e, linkRefFr);
								}}
								onClick={changeLanguage("fr")}
								// isSelected={selectedLanguage === "fr"}
							>
								FR
							</Links>
							<span>
								<FaArrowRightArrowLeft />
							</span>
							<Links
								tabIndex={0}
								ref={linkRefEn}
								onKeyDown={(e) => {
									handleKeyDown(e, linkRefEn);
								}}
								onClick={changeLanguage("en")}
								// isSelected={selectedLanguage === "en"}
							>
								EN
							</Links>
						</div>
					</DivList>
				</Div>

				<div className="toggle">
					{mobileBtn ? (
						<RxCross2
							className="menu_close"
							onClick={() => setMobileBtn(false)}
						/>
					) : (
						<AiOutlineMenu
							className="menu_open"
							onClick={(e) => {
								e.stopPropagation();
								setMobileBtn(true);
							}}
						/>
					)}
				</div>
			</Nav>

			<HR />
		</HeaderNav>
	);
};

export default Header;

const HeaderNav = styled.header`
	position: fixed;
	max-width: 95%;
	top: 0;
	right: 0;
	left: 0;
	height: 90px;
	margin: 0 auto;
	z-index: 1;

	.toggle {
		display: none;
	}

	@media screen and (min-width: 280px) and (max-width: 1023px) {
		/* width: ${(props) => (props.mobileBtn ? "90%" : "120%")}; */
		position: relative;
		margin: 0;

		.toggle {
			display: block;
			z-index: 1;
		}

		.show {
			opacity: 1 !important;
			visibility: visible !important;
		}

		.links {
			/* padding: 0;
			margin: 0; */
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			width: 100vw;
			overflow: hidden;
			height: calc(100vh + 84.5rem);
			background-color: #0e0a0a;
			opacity: 0;
			visibility: hidden;
			transition: 0.4s ease-in-out;
			z-index: 1;

			ul {
				flex-direction: column;
				text-align: center;
				height: 100%;
				justify-content: center;
				margin-top: 20rem;
			}
		}
	}
`;

const Nav = styled.nav`
	position: relative;
	max-width: 95%;
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 5rem;

	.menu_open,
	.menu_close {
		color: #fafafa;
		font-size: 1.5rem;
		display: none;
	}

	@media (max-width: 768px) {
		position: relative;
		display: flex;
		justify-content: space-between;

		a {
			font-size: 1.5rem;
		}

		.menu_open,
		.menu_close {
			display: block;
			/* position: fixed; */
			cursor: pointer;
			font-size: 2rem;
		}
	}
`;

const DivLogo = styled.div`
	width: 10rem;

	@media (max-width: 768px) {
		display: ${(props) => (props.mobileBtn ? "none" : "block")};
		/* display: none; */
	}
`;

const Image = styled.img`
	width: 5rem;
`;

const Div = styled.div`
	width: 60rem;

	@media (max-width: 768px) {
		/* .translate > div {
			display: none;
		} */
	}
`;

const DivList = styled.ul`
	list-style-type: none;
	display: flex;
	/* align-items: center; */
	flex-direction: row;
	justify-content: flex-end;
	font-size: 1.05rem;
	text-transform: uppercase;

	.language {
		display: flex;
		/* gap: -1rem; */
		cursor: pointer;
		/* width: 7rem; */
		justify-content: center;
		align-items: center;

		span {
			color: #fafafa;
		}

		li {
			/* text-decoration: ${(props) =>
				props.isSelected ? "underline" : "none"};
			font-weight: ${(props) => (props.isSelected ? "bold" : "normal")}; */
		}

		@media (min-width: 280px) and (max-width: 1023px) {
			li {
				font-size: 1.5rem;
			}
		}
	}
`;

const Links = styled.li`
	padding: 1rem;
	display: flex;
	align-items: center;
	color: #fafafa;
	font-size: 0.9rem;
	font-family: "Protest Revolution", sans-serif;

	a {
		position: relative;
		text-decoration: none;

		&:after {
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;
			width: 0;
			height: 2px;
		}

		&:hover:after,
		&.active:after {
			width: 100%;
		}

		&:hover {
			text-decoration: line-through;
			transition: text-decoration 0.5s ease; // Transition pour l'animation du soulignement
		}

		&.active {
			text-decoration: line-through;
			transition: text-decoration 0.5s ease; // Transition pour l'animation du soulignement
		}
	}

	@media (min-width: 280px) and (max-width: 768px) {
		display: block;
	}
`;

const HR = styled.hr`
	width: 95%;
	opacity: 0.5;
`;
