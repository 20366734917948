import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { styled } from "styled-components";
import { motion } from "framer-motion";
import { FaArrowRight } from "react-icons/fa6";
import { fadeInTopVariant, fadeInRightVariant } from "../utils/variants";
import BlueBrush from "../assets/brush-paint-stroke.webp";
import RedBrush from "../assets/red-paint-brush-stroke.webp";

const Home = () => {
	const { t } = useTranslation();

	return (
		<MainContainer>
			<SectionContainer>
				<ArticleImg
					as={motion.article}
					variants={fadeInRightVariant}
					initial="hidden"
					whileInView="visible"
					transition={{ type: "tween", duration: 1 }}
				>
					<BlueBrushImg
						src={BlueBrush}
						alt="peinture bleue décorative"
						className="bluebrush"
					/>
					<RedBrushImg
						src={RedBrush}
						alt="peinture rouge décorative"
						className="redbrush"
					/>
				</ArticleImg>
				<ArticleText className="text_block">
					<TitleHome
						as={motion.h1}
						variants={fadeInTopVariant}
						initial="hidden"
						whileInView="visible"
						transition={{ type: "tween", duration: 2, delay: 1 }}
					>
						JOHANE OMISCAR
					</TitleHome>
					<TextContainer
						as={motion.div}
						initial={{ opacity: 0, scale: 0.5 }}
						animate={{ opacity: 1, scale: 1 }}
						transition={{ duration: 1, delay: 0.5 }}
					>
						<Job className="job_title">{t("home.job_title")}</Job>
						<Describe className="describe">{t("home.text")}</Describe>

						<ButtonContainer
							as={motion.button}
							whileHover={{
								scale: 1.1,
								textShadow: "0px 0px 8px rgb(255, 255, 255)",
								boxShadow: "0px 0px 8px rgb(255, 255, 255)",
							}}
						>
							<Link to="/works" className="btn_works">
								{t("home.button")}
								<motion.div
									variants={fadeInRightVariant}
									animate={{ x: 10, opacity: 0 }}
									transition={{ duration: 2, repeat: Infinity }}
								>
									<FaArrowRight />
								</motion.div>
							</Link>
						</ButtonContainer>
					</TextContainer>
				</ArticleText>
			</SectionContainer>
			{/* <motion.div
				className="black_slide"
				initial={{ y: "-0%" }}
				animate={{ y: "100%" }}
				transition={{ duration: 0.7, ease: "easeOut" }}
			/> */}
		</MainContainer>
	);
};

export default Home;

const MainContainer = styled.main`
	padding: 12rem 0 2rem 0;
	position: relative;
	max-width: 80%;
	height: 100vh;

	@media (min-width: 768px) and (max-width: 960px) {
	}

	@media (max-width: 768px) {
		max-width: 60%;
	}

	@media (max-width: 480px) {
		max-width: 80%;
		text-align: center;
	}
`;

const TitleHome = styled.h1`
	font-size: 3.2rem;

	@media (max-width: 768px) {
		font-size: 2.2rem;
	}
`;

const SectionContainer = styled.section`
	display: grid;
	grid-template-columns: 1fr 500px;
	gap: 1rem;
	/* align-items: center; */
	justify-content: center;

	@media (min-width: 768px) and (max-width: 1024px) {
		display: grid;
		grid-template-columns: 1fr 300px;
		gap: 1rem;
		/* justify-content: center; */
		align-items: center;
	}

	@media (min-width: 576px) and (max-width: 768px) {
		display: grid;
		grid-template-columns: 1fr 270px;
		gap: 4rem;
		align-items: center;
	}

	@media (max-width: 480px) {
		display: block;
	}

	/* @media (max-width: 375px) {
		max-width: 100%;
		margin: auto;
	} */
`;

const ArticleImg = styled.article`
	display: flex;
	max-width: 50%;
	position: relative;

	@media (min-width: 768px) and (max-width: 1024px) {
		position: relative;
	}

	@media (max-width: 480px) {
		display: none;
	}

	/* @media (max-width: 375px) {
		max-width: 100%;
		margin: auto;
	} */
`;

const ArticleText = styled.article`
	@media (min-width: 768px) and (max-width: 960px) {
	}
	@media (max-width: 480px) {
		/* display: block; */
		width: 100%;
	}
`;

const BlueBrushImg = styled.img`
	width: 20rem;
	height: 30rem;

	@media (min-width: 768px) and (max-width: 960px) {
		width: 15rem;
		height: 25rem;
	}

	@media (max-width: 768px) {
		width: 12rem;
		height: 18rem;
	}
`;

const RedBrushImg = styled.img`
	width: 15rem;
	height: 20rem;
	position: absolute;
	bottom: 5rem;
	left: 8rem;

	@media (min-width: 768px) and (max-width: 960px) {
		width: 10rem;
		height: 15rem;
		bottom: 5rem;
		left: 6.5rem;
	}

	@media (min-width: 576px) and (max-width: 768px) {
		width: 7rem;
		height: 10rem;
		bottom: 4rem;
		left: 6rem;
	}

	@media (min-width: 480px) and (max-width: 576px) {
		left: 6rem;
		bottom: 4rem;
	}
`;

const TextContainer = styled.div`
	padding-bottom: 1rem;
`;

const Job = styled.p`
	font-style: italic;
	color: #d8d8d8;
	font-weight: bold;
	font-size: 1.3rem;
	text-transform: uppercase;

	@media (max-width: 768px) {
		font-size: 1rem;
	}
`;

const Describe = styled.p`
	color: #a8a29e;
	font-size: 1.1rem;

	@media (max-width: 768px) {
		font-size: 0.9rem;
	}
`;

export const ButtonContainer = styled.button`
	/* width: 10rem; */
	width: fit-content;
	block-size: fit-content;
	padding: 0.8rem;
	background: transparent;
	border: 1px solid #fafafa;
	border-radius: 2rem;
	margin-top: 1.5rem;

	a {
		font-family: "Gowun Batang", serif;
		font-size: 1rem;
		display: flex;
		align-items: flex-end;
	}

	div {
		width: 1.5rem;
		margin-right: 0.5rem;
	}

	@media (max-width: 768px) {
		/* width: 10rem; */
		width: fit-content;
	}

	@media (max-width: 375px) {
		width: max-content;
	}
`;
