import React from "react";
import { Link } from "react-router-dom";
import { styled } from "styled-components";
import { FaRegHandPointLeft } from "react-icons/fa";

const PageNotFound = () => {
	return (
		<MainContainer>
			<SectionContain>
				<h1>404 - Page non trouvée</h1>
				<p>Désolé, la page que vous recherchez est introuvable.</p>
				<Links to="/">
					<FaRegHandPointLeft />
					Retour
				</Links>
			</SectionContain>
		</MainContainer>
	);
};

export default PageNotFound;

const MainContainer = styled.main`
	max-width: 85%;
	padding-top: 5rem;
`;

const SectionContain = styled.section`
	max-width: 70%;
	margin: auto;

	h1 {
		font-size: 3.5rem;
	}

	p {
		font-size: 1.5rem;
	}
`;

const Links = styled(Link)`
	display: flex;
	align-items: center;
	text-transform: uppercase;

	gap: 0.5rem;
	color: #a8a29e;
	font-family: "Special Elite", system-ui;
	font-size: 1.2rem;
`;
