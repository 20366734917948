import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import Swipe from "../assets/swipe.webp";
import NeiltalkOne from "../assets/neiltalk-screen-one.webp";
import NeiltalkTwo from "../assets/neiltalk-screen-two.webp";
// import Neiltalk from "../assets/neiltalk-screen-one.webp";
import SwipeRoussillon from "../assets/roussillon-swipe.webp";
import RoussillonOne from "../assets/roussillon-screen-one.webp";
import RoussillonTwo from "../assets/roussillon-screen-two.webp";
import RoussillonThree from "../assets/roussillon-screen-three.webp";
import NotAvailable from "../assets/not-available.webp";

const Data = () => {
	const { t } = useTranslation();

	const projects = [
		{
			// id: uuidv4(),
			id: 1,
			title: t("works.project_title_one"),
			text: t("work_details.textOne"),
			category: t("works.category"),
			images: {
				img1: SwipeRoussillon,
				img2: RoussillonOne,
				img3: RoussillonTwo,
				img4: RoussillonThree,
			},
			alt: t("works.alt_img_one"),
			link: null,
		},
		{
			// id: uuidv4(),
			id: 2,
			title: t("works.project_title_two"),
			text: t("work_details.textTwo"),
			category: t("works.category"),
			images: {
				img1: Swipe,
				img2: NeiltalkOne,
				img3: NeiltalkTwo,
			},

			alt: t("works.alt_img_two"),
			link: "https://www.neiltalk.fr/",
		},
		{
			// id: uuidv4(),
			id: 3,
			title: t("works.project_title_three"),
			text: t("work_details.unavailable"),
			category: t("works.category"),
			images: {
				img1: null,
				img2: NotAvailable,
				img3: null,
			},
			alt: t("works.alt_img_three"),
			link: null,
		},
	];

	return projects;
};

export default Data;
