import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "styled-components";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { fadeInTitleTopVariant, fadeInLeftVariant } from "../utils/variants";
import Data from "../utils/data";
// import Neiltalk from "../assets/neiltalk-screen-one.webp";
// import NotAvailable from "../assets/not-available.webp";

const Works = () => {
	const { t } = useTranslation();
	const projects = Data();
	console.log(projects);

	// Définir les transitions spécifiques pour chaque article
	const articleTransitions = [
		{ duration: 1, delay: 0.5 },
		{ duration: 1, delay: 0.8 },
		{ duration: 1, delay: 1.1 },
	];

	// Définir les transitions spécifiques pour chaque div
	const divTransitions = [
		{ type: "tween", duration: 1, delay: 0.2 },
		{ type: "tween", duration: 1, delay: 0.5 },
		{ type: "tween", duration: 1, delay: 0.8 },
	];

	return (
		<MainContainer>
			<motion.h1
				variants={fadeInTitleTopVariant}
				initial="hidden"
				whileInView="visible"
			>
				{t("works.work_title")}
			</motion.h1>
			<Container>
				<SectionFirst>
					<ArticleFirst
						as={motion.article}
						initial={{ opacity: 0, scale: 0.5 }}
						animate={{ opacity: 1, scale: 1 }}
						transition={{ duration: 1 }}
					>
						{t("works.subtitle")}
					</ArticleFirst>
				</SectionFirst>

				<SectionSecond>
					{projects.map((project, index) => (
						<article className={`article-${index}`} key={project.id}>
							<Link to={`/work-details/project/${project.id}`}>
								<motion.div
									className="picture"
									initial={{ opacity: 0, scale: 0.5 }}
									animate={{ opacity: 1, scale: 1 }}
									transition={articleTransitions[index]} // Utilisation de la transition spécifique pour cet article
								>
									<Img src={project.images.img2} alt={project.alt} />
								</motion.div>
							</Link>
							<DivText
								as={motion.div}
								variants={fadeInLeftVariant}
								initial="hidden"
								whileInView="visible"
								transition={divTransitions[index]}
							>
								<Title>{project.title}</Title>
								<Text>{project.text.substring(0, 66)}...</Text>
								<Category>{project.category}</Category>
							</DivText>
						</article>
					))}

					{/* <ArticleSecond>
						<Link to="/work-details">
							<motion.div
								className="picture"
								initial={{ opacity: 0, scale: 0.5 }}
								animate={{ opacity: 1, scale: 1 }}
								transition={{ duration: 1, delay: 0.5 }}
							>
								<Img src={Neiltalk} alt={t("works.alt_img_one")} />
							</motion.div>
						</Link>
						<DivText
							as={motion.div}
							variants={fadeInLeftVariant}
							initial="hidden"
							whileInView="visible"
							transition={{ type: "tween", duration: 1, delay: 0.2 }}
						>
							<Title>{t("works.project_title_one")}</Title>
							<Text>{t("works.project_text_one")}</Text>
							<Category>{t("works.category")}</Category>
						</DivText>
					</ArticleSecond>

					<ArticleThird>
						<motion.div
							className="picture"
							initial={{ opacity: 0, scale: 0.5 }}
							animate={{ opacity: 1, scale: 1 }}
							transition={{ duration: 1, delay: 0.8 }}
						>
							<Img src={NotAvailable} alt={t("works.alt_img_two")} />
						</motion.div>
						<DivText
							as={motion.div}
							variants={fadeInLeftVariant}
							initial="hidden"
							whileInView="visible"
							transition={{ type: "tween", duration: 1, delay: 0.5 }}
						>
							<Title>{t("works.project_title_two")}</Title>
							<Text>{t("works.project_text_two")}</Text>
							<Category>{t("works.category")}</Category>
						</DivText>
					</ArticleThird>

					<ArticleFour>
						<motion.div
							className="picture"
							initial={{ opacity: 0, scale: 0.5 }}
							animate={{ opacity: 1, scale: 1 }}
							transition={{ duration: 1, delay: 1.1 }}
						>
							<Img src={NotAvailable} alt={t("works.alt_img_three")} />
						</motion.div>
						<DivText
							as={motion.div}
							variants={fadeInLeftVariant}
							initial="hidden"
							whileInView="visible"
							transition={{ type: "tween", duration: 1, delay: 0.8 }}
						>
							<Title>{t("works.project_title_three")}</Title>
							<Text>{t("works.project_text_three")}</Text>
							<Category>{t("works.category")}</Category>
						</DivText>
					</ArticleFour> */}
				</SectionSecond>
			</Container>
		</MainContainer>
	);
};

export default Works;

const MainContainer = styled.main`
	padding: 6rem 0 5rem 0;
`;

const Container = styled.div`
	display: grid;
	grid-template-columns: 250px 1fr;
	gap: 2rem;
	align-items: center;
	padding-top: 3rem;

	@media (min-width: 480px) and (max-width: 576px) {
		max-width: 80%;
		margin: auto;
		display: flex;
		flex-direction: column;
	}

	@media (min-width: 575px) and (max-width: 768px) {
		max-width: 90%;
		margin: auto;
		display: flex;
		flex-direction: column;
	}

	@media (min-width: 768px) and (max-width: 960px) {
		max-width: 90%;
		margin: auto;
		display: flex;
		flex-direction: column;
	}

	@media (min-width: 375px) and (max-width: 480px) {
		max-width: 70%;
		margin: auto;
		display: flex;
		flex-direction: column;
	}
`;

const SectionFirst = styled.section``;

const ArticleFirst = styled.article`
	color: #a8a29e;
	text-align: center;
	font-size: 1.2rem;
	border: 1px solid #a8a29e;
	border-radius: 1rem;
	padding: 1rem;

	@media (min-width: 576px) and (max-width: 768px) {
		margin-bottom: 1.5rem;
	}

	@media (min-width: 480px) and (max-width: 576px) {
		font-size: 1rem;
	}
`;

const SectionSecond = styled.section`
	article {
		margin-bottom: 3rem;
		display: flex;

		@media (max-width: 480px) {
		}

		@media (min-width: 768px) and (max-width: 960px) {
		}

		@media (min-width: 576px) and (max-width: 768px) {
			align-items: center;
			/* display: flex; */
			flex-direction: column;
		}

		@media (min-width: 480px) and (max-width: 576px) {
			margin: 0;
			padding-top: 2rem;
			display: block;
		}

		@media (min-width: 375px) and (max-width: 480px) {
			margin: 0;
			padding-top: 2rem;
			display: block;
		}

		&:nth-child(2) {
			margin: 0 0 3rem 8rem;
			display: flex;

			@media (min-width: 768px) and (max-width: 960px) {
				margin: 0;
			}

			@media (min-width: 576px) and (max-width: 768px) {
				align-items: center;
				margin: 0;
				/* margin: 0 0 2rem 3rem; */
				padding: 2rem 0 1rem 0;
				flex-direction: column;
			}

			@media (min-width: 480px) and (max-width: 576px) {
				margin: 0;
				padding-top: 2rem;
				display: block;
			}

			@media (min-width: 375px) and (max-width: 480px) {
				margin: 0;
				padding-top: 2rem;
				display: block;
			}
		}

		&:nth-child(3) {
			margin-left: 16rem;
			display: flex;

			@media (min-width: 768px) and (max-width: 960px) {
				margin: 0;
				padding-top: 3rem;
			}

			@media (min-width: 576px) and (max-width: 768px) {
				align-items: center;

				/* margin-left: 7rem; */
				margin: 0;
				padding-top: 2rem;
				flex-direction: column;
			}

			@media (min-width: 480px) and (max-width: 576px) {
				margin: 0;
				display: block;
				padding-top: 2rem;
			}

			@media (min-width: 375px) and (max-width: 480px) {
				margin: 0;
				padding-top: 2rem;
				display: block;
			}
		}

		/* .first-article {
			margin-bottom: 3rem;
			display: flex;

			@media (max-width: 480px) {
			}

			@media (min-width: 768px) and (max-width: 960px) {
			}

			@media (min-width: 576px) and (max-width: 768px) {
				align-items: center; */
		/* display: flex; */
		/* flex-direction: column;
			}

			@media (min-width: 480px) and (max-width: 576px) {
				margin: 0;
				padding-top: 2rem;
				display: block;
			}

			@media (min-width: 375px) and (max-width: 480px) {
				margin: 0;
				padding-top: 2rem;
				display: block;
			}
		} */

		/* .second-article {
			margin: 0 0 3rem 8rem;
			display: flex;

			@media (min-width: 768px) and (max-width: 960px) {
				margin: 0;
			}

			@media (min-width: 576px) and (max-width: 768px) {
				align-items: center;
				margin: 0; */
		/* margin: 0 0 2rem 3rem; */
		/* padding-top: 2rem;
				flex-direction: column;
			}

			@media (min-width: 480px) and (max-width: 576px) {
				margin: 0;
				padding-top: 2rem;
				display: block;
			}

			@media (min-width: 375px) and (max-width: 480px) {
				margin: 0;
				padding-top: 2rem;
				display: block;
			}
		} */

		.third-article {
			margin-left: 16rem;
			display: flex;

			@media (min-width: 768px) and (max-width: 960px) {
				margin: 0;
				padding-top: 3rem;
			}

			@media (min-width: 576px) and (max-width: 768px) {
				align-items: center;

				/* margin-left: 7rem; */
				margin: 0;
				padding-top: 2rem;
				flex-direction: column;
			}

			@media (min-width: 480px) and (max-width: 576px) {
				margin: 0;
				display: block;
				padding-top: 2rem;
			}

			@media (min-width: 375px) and (max-width: 480px) {
				margin: 0;
				padding-top: 2rem;
				display: block;
			}
		}
	}

	.picture {
		width: 15rem;
		height: 10rem;
		border: 3px solid #fafafa;
		overflow: hidden;
	}

	@media (max-width: 576px) {
	}

	@media (max-width: 480px) {
	}

	@media (max-width: 375px) {
	}

	@media (min-width: 576px) and (max-width: 768px) {
		max-width: 100%;
		margin: auto;
		/* display: flex;
		flex-direction: column; */
		display: block;
		align-items: center;
	}

	@media (min-width: 480px) and (max-width: 576px) {
		max-width: 80%;
		margin: auto;
	}

	@media (min-width: 375px) and (max-width: 480px) {
		max-width: 80%;
		margin: auto;
	}
`;

const ArticleSecond = styled.article`
	margin-bottom: 3rem;
	display: flex;

	@media (max-width: 480px) {
	}

	@media (min-width: 768px) and (max-width: 960px) {
	}

	@media (min-width: 576px) and (max-width: 768px) {
		align-items: center;
		/* display: flex; */
		flex-direction: column;
	}

	@media (min-width: 480px) and (max-width: 576px) {
		margin: 0;
		padding-top: 2rem;
		display: block;
	}

	@media (min-width: 375px) and (max-width: 480px) {
		margin: 0;
		padding-top: 2rem;
		display: block;
	}
`;

const Img = styled.img`
	width: 100%;
	height: 100%;
	object-fit: center;

	@media (min-width: 768px) and (max-width: 960px) {
		/* width: 20rem; */
	}

	@media (min-width: 576px) and (max-width: 768px) {
		/* width: 20rem; */
	}
`;

const DivText = styled.div`
	/* width: 60%; */
	padding-left: 1.5rem;

	@media (max-width: 768px) {
		width: 60%;
	}
`;

const Title = styled.h2`
	text-transform: uppercase;
`;

const Text = styled.p`
	font-size: 1.1rem;

	@media (max-width: 768px) {
		font-size: 0.9rem;
	}
`;

const Category = styled.p`
	color: #a8a29e;
	font-weight: bold;
	font-size: 0.8rem;
	font-style: italic;
	text-transform: uppercase;

	@media (max-width: 768px) {
		font-size: 0.7rem;
	}
`;

const ArticleThird = styled.article`
	margin: 0 0 3rem 8rem;
	display: flex;

	@media (min-width: 768px) and (max-width: 960px) {
		margin: 0;
	}

	@media (min-width: 576px) and (max-width: 768px) {
		align-items: center;
		margin: 0;
		/* margin: 0 0 2rem 3rem; */
		padding-top: 2rem;
		flex-direction: column;
	}

	@media (min-width: 480px) and (max-width: 576px) {
		margin: 0;
		padding-top: 2rem;
		display: block;
	}

	@media (min-width: 375px) and (max-width: 480px) {
		margin: 0;
		padding-top: 2rem;
		display: block;
	}
`;

const ArticleFour = styled.article`
	margin-left: 16rem;
	display: flex;

	@media (min-width: 768px) and (max-width: 960px) {
		margin: 0;
		padding-top: 3rem;
	}

	@media (min-width: 576px) and (max-width: 768px) {
		align-items: center;

		/* margin-left: 7rem; */
		margin: 0;
		padding-top: 2rem;
		flex-direction: column;
	}

	@media (min-width: 480px) and (max-width: 576px) {
		margin: 0;
		display: block;
		padding-top: 2rem;
	}

	@media (min-width: 375px) and (max-width: 480px) {
		margin: 0;
		padding-top: 2rem;
		display: block;
	}
`;
