import React, { useState, useRef, useEffect } from "react";
import { styled } from "styled-components";
import { RxCross2 } from "react-icons/rx";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

const Credits = ({ openModal, closeModal }) => {
	const { t } = useTranslation();

	const ref = useRef();
	const btnRef = useRef(null);

	useEffect(
		(e) => {
			if (openModal || e.key !== "Enter") {
				ref.current.show();
			} else {
				ref.current.close();
			}
		},
		[openModal],
	);

	const handleKeyPress = (event) => {
		if (event.key === "Enter") {
			// Gérer l'action à effectuer lorsque la touche Entrée est pressée
			console.log("Touche Entrée pressée sur la div !");
		}
	};

	return (
		<>
			<Overlay className="overlay">
				<DialogContainer ref={ref} onCancel={closeModal}>
					<div>
						<h1>{t("footer.legal")}</h1>
						<h2>Photos :</h2>
						<ul>
							<li>Pawel Czerwinski</li>
							<li>Cassi Josh</li>
						</ul>
					</div>
					<p>{t("footer.credits_modal.use")}</p>

					<RxCross2
						className="btn"
						tabIndex={0}
						ref={btnRef}
						onClick={closeModal}
						onKeyDown={closeModal}
					/>
				</DialogContainer>
			</Overlay>
		</>
	);
};

export default Credits;

const Overlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 130vh;

	background-color: rgba(0, 0, 0, 0.4); /* Fond semi-transparent */
	backdrop-filter: blur(0.5rem);
`;

const DialogContainer = styled.dialog`
	width: 40%;
	height: 40%;

	right: 0;
	bottom: 0;
	position: absolute;
	top: 40%;
	left: 40%;
	transform: translate(-50%, -50%);
	line-height: 1.4;
	background: #0e0a0a;
	border-radius: 1rem;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);

	li {
		color: #fafafa;
	}

	h1,
	h2,
	p,
	li {
		font-family: "Gowun Batang", serif;
	}

	p {
		padding-left: 2rem;
	}

	h1 {
		font-size: 2.5rem;
		text-decoration: overline;
		text-transform: uppercase;
	}

	h2 {
		font-size: 2rem;
	}

	li {
		font-size: 1.3rem;
	}

	div {
		margin-bottom: 1rem;
		padding-left: 2rem;
	}

	.btn {
		position: absolute;
		top: 10px;
		right: 10px;
		padding: 5px 7px;
		cursor: pointer;
		font-size: 1.5rem;
		color: #fafafa;
	}
`;
