import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { styled } from "styled-components";
import { FaRegHandPointLeft } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
// import hoverEffect from "hover-effect";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCreative } from "swiper/modules";
import {
	fadeInTopVariant,
	fadeInLeftVariant,
	fadeInRightVariant,
} from "../utils/variants";
import { ButtonContainer } from "./Home";
// import Swipe from "../assets/swipe.webp";
// import NeiltalkOne from "../assets/neiltalk-screen-one.webp";
// import NeiltalkTwo from "../assets/neiltalk-screen-two.webp";
// import Overlay from "../assets/overlay.webp";
import Data from "../utils/data";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-creative";

const WorkDetails = () => {
	const { projectId } = useParams();
	const projects = Data();
	const project = projects.find((p) => p.id === parseInt(projectId));
	// console.log(projects);
	// console.log(project);

	const { t } = useTranslation();

	const linkRef = useRef(null);
	// const imageRef = useRef(null);
	const navigate = useNavigate();

	const handleKeyDown = (e) => {
		if (e.key === "Enter") {
			navigate("/works");
		}
	};

	const images = [
		{
			image: project.images.img1,
		},
		{
			image: project.images.img2,
		},
		{
			image: project.images.img3,
		},
		{
			image: project.images.img4 || null,
		},
	];
	// useEffect(() => {
	// 	// if (imageRef.current) {
	// 	new hoverEffect(
	// 		{
	// 			parent: document.querySelector(".picture_img"),
	// 			// parent: imageRef.current,
	// 			intensity: 0.3,
	// 			image1: HoverImage,
	// 			image2: NeiltalkOne,
	// 			image3: NeiltalkTwo,
	// 			displacementImage: Overlay,
	// 		},
	// 		[],
	// 	);
	// 	// }
	// });

	return (
		<MainContainer>
			<SectionWork>
				<Blur />
				<ArticleWorkFirst
					as={motion.div}
					variants={fadeInLeftVariant}
					initial="hidden"
					whileInView="visible"
				>
					<Links
						href="/works"
						tabIndex={0}
						ref={linkRef}
						onKeyDown={handleKeyDown}
						as={motion.a}
						variants={{ fadeInLeftVariant }}
						animate={{ x: 10, opacity: 0 }}
						transition={{ duration: 2, repeat: Infinity }}
					>
						<FaRegHandPointLeft />
						{t("work_details.back")}
					</Links>
				</ArticleWorkFirst>

				<ArticleWork>
					<motion.div
						className="picture"
						initial={{ opacity: 0, scale: 0.5 }}
						animate={{ opacity: 1, scale: 1 }}
						transition={{ duration: 1, delay: 0.5 }}
					>
						{/* <div className="picture_img"></div> */}
						{/* <ImgWork
							ref={imageRef}
							src={HoverImage}
							alt={t("works.alt_img_one")}
						/> */}
						<Swiper
							grabCursor={true}
							effect={"creative"}
							creativeEffect={{
								prev: {
									shadow: true,
									translate: [0, 0, -400],
								},
								next: {
									translate: ["100%", 0, 0],
								},
							}}
							modules={[EffectCreative]}
							className="picture_img"
						>
							{images.map((img) => (
								<SwiperSlide key={img.image}>
									<ImgWork src={img.image} alt={t("works.alt_img_one")} />
								</SwiperSlide>
							))}
						</Swiper>
					</motion.div>
					<DivWork
						as={motion.div}
						variants={fadeInTopVariant}
						initial="hidden"
						whileInView="visible"
						transition={{ type: "tween", duration: 2, delay: 1 }}
					>
						<WorkTitle>{project.title}</WorkTitle>
						<WorkText>{project.text}</WorkText>
					</DivWork>
					{project.link !== null && (
						<ButtonContainer
							as={motion.button}
							whileHover={{
								scale: 1.1,
								textShadow: "0px 0px 8px rgb(255, 255, 255)",
								boxShadow: "0px 0px 8px rgb(255, 255, 255)",
							}}
						>
							<Link to={project.link} className="btn_works">
								{t("work_details.link")}
								<motion.div
									variants={fadeInRightVariant}
									animate={{ x: 10, opacity: 0 }}
									transition={{ duration: 2, repeat: Infinity }}
								>
									<FaArrowRight />
								</motion.div>
							</Link>
						</ButtonContainer>
					)}
				</ArticleWork>
			</SectionWork>

			<FooterWork>
				<HR />
				<Text>{project.category}</Text>
			</FooterWork>
		</MainContainer>
	);
};

export default WorkDetails;

const MainContainer = styled.main`
	max-width: 85%;
	padding-top: 5rem;

	@media (max-width: 375px) {
		max-width: 100%;
		margin: auto;
	}
`;

const SectionWork = styled.section`
	display: grid;
	grid-template-columns: 250px 1fr;

	gap: 3rem;
	padding-bottom: 5rem;
	z-index: 2;

	@media (min-width: 576px) and (max-width: 768px) {
		/* max-width: 80%;
		margin: auto; */
		display: flex;
		flex-direction: column;
	}

	@media (max-width: 480px) {
	}
`;

const Blur = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: auto;
	height: auto;
	/* filter: blur(5rem); */
	backdrop-filter: blur(5em);
	-webkit-filter: blur(5em);
	-moz-filter: blur(5em);
	-o-filter: blur(5em);
	-ms-filter: blur(5em);
	z-index: -1;
`;

const ArticleWorkFirst = styled.article`
	width: 10rem;
	z-index: 2;
`;

const Links = styled(Link)`
	display: flex;
	align-items: center;
	text-transform: uppercase;
	gap: 0.5rem;
	color: #a8a29e;
	font-family: "Special Elite", system-ui;
	font-size: 1.2rem;
	z-index: 100;
`;

const ArticleWork = styled.article`
	.picture {
		width: 30rem;
		height: 20rem;
		border: 3px solid #fafafa;
		overflow: hidden;
	}

	.picture_img {
		width: 100%;
		height: 100%;
		object-fit: center;

		@media (max-width: 768px) {
			display: flex;
			flex-direction: column;
		}
	}

	@media (min-width: 576px) and (max-width: 768px) {
		max-width: 80%;
		margin: auto;
		display: flex;
		flex-direction: column;
	}

	@media (max-width: 375px) {
		max-width: 100%;
		margin: auto;
	}
`;

const ImgWork = styled.img`
	width: 100%;
	height: 100%;
	object-fit: center;

	@media (max-width: 768px) {
		display: flex;
		flex-direction: column;
	}

	@media (max-width: 480px) {
		/* width: 20rem; */
	}

	@media (max-width: 375px) {
		/* width: 19rem; */
	}
`;

const DivWork = styled.div`
	width: 30rem;
	margin-top: 3.5rem;
`;

const WorkTitle = styled.h2`
	text-transform: uppercase;
`;

const WorkText = styled.p`
	color: #a8a29e;

	@media (max-width: 768px) {
		display: flex;
		flex-direction: column;
		width: 25rem;
		font-size: 0.9rem;
	}

	@media (max-width: 480px) {
		width: 20rem;
	}
`;

const FooterWork = styled.footer`
	padding: 0.5rem;
`;

const HR = styled.hr`
	opacity: 0.5;
`;

const Text = styled.p`
	padding-top: 0.5rem;
	width: 80%;
	margin-left: 2rem;
	font-style: italic;
	text-transform: uppercase;

	@media (max-width: 768px) {
		font-size: 0.7rem;
	}
`;
