import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translationEN from "./translations/en.json";
import translationFR from "./translations/fr.json";

const resources = {
	fr: {
		translation: translationFR,
	},
	en: {
		translation: translationEN,
	},
};

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		resources,
		lng: "fr",

		keySeparator: ".",

		interpolation: {
			escapeValue: false,
		},
	});

export default i18n;
