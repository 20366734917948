import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "styled-components";
import { motion } from "framer-motion";
import emailjs from "@emailjs/browser";
import {
	fadeInTitleTopVariant,
	fadeInLeftVariant,
	fadeInRightVariant,
} from "../utils/variants";

const Contact = () => {
	const [name, setName] = useState("");
	const [company, setCompany] = useState("");
	const [phone, setPhone] = useState("");
	const [email, setEmail] = useState("");
	const [subject, setSubject] = useState("");
	const [message, setMessage] = useState("");

	const { t } = useTranslation();

	// const isEmail = () => {
	// 	let mail = document.getElementById("not-mail");
	// 	let regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

	// 	if (email.match(regex)) {
	// 		mail.style.display = "none";
	// 		return true;
	// 	} else {
	// 		mail.style.display = "block";
	// 		// mail.style.animation = "dongle 1s";
	// 		setTimeout(() => {
	// 			mail.style.animation = "none";
	// 		}, 1000);
	// 		return false;
	// 	}
	// };

	const failMessage = (message) => {
		let formMess = document.querySelector(".form-message");
		formMess.innerHTML = message;
		formMess.style.textAlign = "center";
		formMess.style.opacity = "1";
		formMess.style.display = "block";
		formMess.style.background = "rgb(253,87,87)";
		// formMess.style.width = "110px";

		document.getElementById("name").classList.add("error");
		document.getElementById("email").classList.add("error");
		document.getElementById("message").classList.add("error");

		setTimeout(() => {
			formMess.style.opacity = "0";
			formMess.style.display = "none";
		}, 5000);
	};

	const successMessage = () => {
		let formMess = document.querySelector(".form-message");
		formMess.innerHTML =
			"Message envoyé ! Nous vous recontacterons dès que possible.";
		formMess.style.background = "#00c1ec";
		formMess.style.textAlign = "center";
		formMess.style.opacity = "1";
		formMess.style.display = "block";

		document.getElementById("name").classList.remove("error");
		document.getElementById("email").classList.remove("error");
		document.getElementById("message").classList.remove("error");

		setTimeout(() => {
			formMess.style.opacity = "0";
			formMess.style.display = "none";
		}, 5000);
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		if (name /*&& isEmail()*/ && message && email) {
			sendFeedback(
				"template_x9xs608",
				{
					name,
					company,
					phone,
					email,
					message,
				},
				"user_zRaqybscjuiyJtQnEFUfR",
			);
		} else {
			failMessage("Merci de remplir correctement les champs requis");
		}
	};

	const sendFeedback = (templateId, variables, publicKey) => {
		emailjs
			.send("service_z48aqdf", templateId, variables, publicKey)
			.then((res) => {
				successMessage();
				setName("");
				setCompany("");
				setPhone("");
				setEmail("");
				setMessage("");
			})
			.catch((err) => {
				failMessage("Une erreur s'est produite, veuillez réessayer.");
			});
	};

	return (
		<MainContainer>
			<motion.h1
				variants={fadeInTitleTopVariant}
				initial="hidden"
				whileInView="visible"
			>
				Let's talk
			</motion.h1>
			<SectionContainer>
				<ArticleText
					as={motion.article}
					variants={fadeInRightVariant}
					initial="hidden"
					whileInView="visible"
					transition={{ type: "tween", duration: 1 }}
				>
					<DivText>{t("contact.description")}</DivText>
					<DivNumber>
						<NumberList>
							<NumberText>{t("contact.phone")}</NumberText>
							<Number>07.45.08.29.97</Number>
						</NumberList>
					</DivNumber>

					<DivEmail>
						<EmailList>
							<EmailText>Email</EmailText>
							<Email>JOHANE.OMISCAR@GMAIL.COM</Email>
						</EmailList>
					</DivEmail>
				</ArticleText>

				<SectionForm
					as={motion.section}
					variants={fadeInLeftVariant}
					initial="hidden"
					whileInView="visible"
					transition={{ type: "tween", duration: 1 }}
				>
					<FormContainer method="POST">
						<DivLabel>
							<div>
								<LabelInput htmlFor="name">
									{t("contact.name")}, {t("contact.firstname")}
								</LabelInput>
								<InputGrid
									type="text"
									id="name"
									name="name"
									value={name}
									onChange={(e) => setName(e.target.value)}
								/>
							</div>

							<div>
								<LabelInput htmlFor="company">
									{t("contact.company")}
								</LabelInput>
								<InputGrid
									type="text"
									id="company"
									name="company"
									value={company}
									onChange={(e) => setCompany(e.target.value)}
								/>
							</div>

							<div>
								<LabelInput htmlFor="email">Email</LabelInput>
								<InputGrid
									type="email"
									id="email"
									name="email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</div>

							<div>
								<LabelInput htmlFor="phone">{t("contact.phone")}</LabelInput>
								<InputGrid
									type="phone"
									id="phone"
									name="phone"
									value={phone}
									onChange={(e) => setPhone(e.target.value)}
								/>
							</div>
						</DivLabel>

						<div>
							<LabelInput htmlFor="subject">{t("contact.subject")}</LabelInput>
							<InputSubject
								type="subject"
								id="subject"
								name="subject"
								value={subject}
								onChange={(e) => setSubject(e.target.value)}
							/>
						</div>

						<div>
							<LabelInput htmlFor="message">Message</LabelInput>
							<TextZone
								id="message"
								name="message"
								rows="6"
								cols="43"
								placeholder={t("contact.placeholder")}
								value={message}
								onChange={(e) => setMessage(e.target.value)}
							></TextZone>
						</div>
						<div className="form-message"></div>
						<DivBtn>
							<Btn type="submit" onClick={handleSubmit}>
								{t("contact.send")}
							</Btn>
						</DivBtn>
					</FormContainer>
				</SectionForm>
			</SectionContainer>
		</MainContainer>
	);
};

export default Contact;

const MainContainer = styled.main`
	color: #fafafa;
	height: 100vh;

	padding: 6rem 0 5rem 0;

	/* @media (min-width: 1250px) and (max-width: 1440px) {
		height: 100vh;
		margin-top: 10rem;
		padding-bottom: 0;
	} */
`;

const SectionContainer = styled.section`
	max-width: 90%;
	margin: auto;
	display: grid;
	grid-template-columns: 1fr 450px;
	gap: 10rem;
	align-items: center;

	@media (min-width: 768px) and (max-width: 960px) {
		max-width: 80%;
		margin: auto;
		display: flex;
		justify-content: space-evenly;
		gap: 4rem;
	}

	@media (min-width: 576px) and (max-width: 768px) {
		max-width: 80%;
		margin: auto;
		display: flex;
		flex-direction: column;
		gap: 5rem;
		/* gap: 4rem; */
	}

	@media (min-width: 480px) and (max-width: 576px) {
		max-width: 80%;
		margin: auto;
		display: flex;
		flex-direction: column;
		gap: 5rem;
	}

	@media (min-width: 375px) and (max-width: 480px) {
		max-width: 80%;
		margin: auto;
		display: flex;
		flex-direction: column;
		gap: 5rem;
	}
`;

const ArticleText = styled.article`
	@media (min-width: 576px) and (max-width: 768px) {
		max-width: 80%;
		margin: auto;
	}
`;

const DivText = styled.p`
	font-size: 1.25rem;
	margin-bottom: 4rem;
	text-align: center;
`;

const DivNumber = styled.div``;

const NumberList = styled.ul``;

const NumberText = styled.li`
	text-transform: uppercase;
	font-size: 1.2rem;
	font-family: "Grechen Fuemen", "cursive";
`;

const Number = styled.li`
	color: #a8a29e;
	font-family: "Gowun Batang", serif;
`;

const DivEmail = styled.div``;

const EmailList = styled.ul``;

const EmailText = styled.li`
	text-transform: uppercase;
	font-family: "Grechen Fuemen", "cursive";
	font-size: 1.2rem;
`;

const Email = styled.li`
	color: #a8a29e;
	font-family: "Gowun Batang", serif;
`;

const FormContainer = styled.form`
	width: 85%;

	@media (min-width: 768px) and (max-width: 960px) {
		width: fit-content;
	}

	@media (min-width: 576px) and (max-width: 768px) {
		width: fit-content;
	}

	@media (min-width: 480px) and (max-width: 576px) {
		width: fit-content;
	}

	@media (min-width: 375px) and (max-width: 480px) {
		width: fit-content;
	}
`;

const SectionForm = styled.section`
	/* width: 90%; */
	@media (min-width: 576px) and (max-width: 768px) {
	}
`;

const DivLabel = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	/* gap: 1rem; */
`;

const LabelInput = styled.label`
	display: block;
	padding-bottom: 0.2rem;
`;

const InputGrid = styled.input`
	width: 10rem;
	padding: 0.3rem;
	margin-bottom: 1rem;
	opacity: 0.5;
`;

const InputSubject = styled.input`
	width: 22rem;
	padding: 0.3rem;
	margin-bottom: 1rem;
	opacity: 0.5;
`;

const TextZone = styled.textarea`
	margin-bottom: 1rem;
	resize: none;
	opacity: 0.5;
`;

const DivBtn = styled.div`
	margin: auto;
	max-width: 8rem;
`;

const Btn = styled.button`
	cursor: pointer;
	color: #fafafa;
	font-size: 1rem;
	width: 7rem;
	padding: 0.6rem;
	background: transparent;
	border: 1px solid #fafafa;
	border-radius: 1rem;
`;
