import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "styled-components";
import { motion } from "framer-motion";
import {
	fadeInTitleTopVariant,
	fadeInLeftVariant,
	fadeInRightVariant,
} from "../utils/variants";
import ImgServices from "../assets/img-services.webp";

const Services = () => {
	const { t } = useTranslation();

	return (
		<MainContainer>
			<motion.h1
				variants={fadeInTitleTopVariant}
				initial="hidden"
				whileInView="visible"
			>
				{t("services.title")}
			</motion.h1>

			<SectionServices>
				<ArticleOne
					as={motion.article}
					variants={fadeInRightVariant}
					initial="hidden"
					whileInView="visible"
					transition={{ type: "tween", duration: 1 }}
				>
					<ListLeft>
						<li>
							<TitleServices>{t("services.first_service_title")}</TitleServices>
						</li>
						<li>
							<p>{t("services.first_service_text")}</p>
						</li>
					</ListLeft>

					<ListLeft>
						<li>
							<TitleServices>
								{t("services.second_service_title")}
							</TitleServices>
						</li>
						<li>
							<p>{t("services.second_service_text")}</p>
						</li>
					</ListLeft>

					<ListLeft>
						<li>
							<TitleServices>{t("services.third_service_title")}</TitleServices>
						</li>
						<li>
							<p>{t("services.third_service_text")}</p>
						</li>
					</ListLeft>
				</ArticleOne>

				<ImgService
					src={ImgServices}
					className="img-services"
					alt="ordinateur"
					as={motion.img}
					initial={{ opacity: 0, scale: 0.5 }}
					animate={{ opacity: 1, scale: 1 }}
					transition={{ duration: 1, delay: 0.5 }}
				/>

				<ArticleTwo
					as={motion.article}
					variants={fadeInLeftVariant}
					initial="hidden"
					whileInView="visible"
					transition={{ type: "tween", duration: 1 }}
				>
					<ListRight>
						<li>
							<TitleServices>
								{t("services.fourth_service_title")}
							</TitleServices>
						</li>
						<li>
							<p>{t("services.fourth_service_text")}</p>
						</li>
					</ListRight>

					<ListRight>
						<li>
							<TitleServices>{t("services.fifth_service_title")}</TitleServices>
						</li>
						<li>
							<p>{t("services.fifth_service_text")}</p>
						</li>
					</ListRight>

					<ListRight>
						<li>
							<TitleServices>{t("services.sixth_service_title")}</TitleServices>
						</li>
						<li>
							<p>{t("services.sixth_service_text")}</p>
						</li>
					</ListRight>
				</ArticleTwo>
			</SectionServices>
		</MainContainer>
	);
};

export default Services;

const MainContainer = styled.main`
	/* max-width: 85%; */
	/* margin: 0 auto; */
	padding: 6rem 0 5rem 0;
`;

const SectionServices = styled.section`
	display: flex;
	justify-content: space-evenly;
	align-items: center;

	max-width: 100%;
	margin: 0 auto;

	@media (min-width: 768px) and (max-width: 960px) {
		max-width: 90%;
		margin: auto;
	}

	@media (min-width: 576px) and (max-width: 768px) {
		max-width: 90%;
		display: block;

		text-align: center;

		p {
			font-size: 0.9rem;
		}
	}

	@media (min-width: 480px) and (max-width: 576px) {
		max-width: 100%;
		margin: auto;
		display: block;
		text-align: center;
	}

	@media (min-width: 375px) and (max-width: 480px) {
		max-width: 100%;
		margin: auto;
		display: block;
		text-align: center;
	}
`;

const ArticleOne = styled.article`
	width: 22rem;
	text-align: right;
	padding: 0 3rem 0 0;

	@media (min-width: 768px) and (max-width: 960px) {
		width: 20rem;
		right: 0;
	}

	@media (min-width: 576px) and (max-width: 768px) {
		width: 25rem;
		text-align: center;
		/* right: 0; */
	}

	@media (min-width: 480px) and (max-width: 576px) {
		width: 25rem;
		text-align: center;
	}

	@media (min-width: 375px) and (max-width: 480px) {
		width: 18rem;
		text-align: center;
	}
`;

const TitleServices = styled.h2`
	font-size: 1.6rem;
	text-transform: uppercase;
	text-decoration: underline;
	background: linear-gradient(to right, #544eba 0%, #c21412 60%, #e3b403 100%);
	background-clip: text;
	-webkit-text-fill-color: transparent;
`;

const ListLeft = styled.ul``;

const ImgService = styled.img`
	width: 17rem;
	height: 17rem;

	@media (min-width: 768px) and (max-width: 960px) {
		width: 15rem;
		height: 15rem;
	}

	@media (min-width: 576px) and (max-width: 768px) {
		width: 10rem;
		height: 10rem;
	}

	@media (min-width: 375px) and (max-width: 480px) {
		width: 12rem;
		height: 12rem;
	}
`;

const ArticleTwo = styled.article`
	width: 24rem;
	/* text-align: left; */

	@media (min-width: 768px) and (max-width: 960px) {
		width: 22rem;
	}

	@media (min-width: 576px) and (max-width: 768px) {
		width: 26rem;
		/* right: 0; */
	}

	@media (min-width: 480px) and (max-width: 576px) {
		width: 26rem;
		text-align: center;
	}

	@media (min-width: 375px) and (max-width: 480px) {
		width: 18rem;
		text-align: center;
	}
`;

const ListRight = styled.ul``;
