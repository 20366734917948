import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { styled } from "styled-components";
import { motion, useScroll, useTransform } from "framer-motion";
import {
	fadeInTitleTopVariant,
	fadeInBottomVariant,
	fadeInTopVariant,
	fadeInLeftVariant,
} from "../utils/variants";

const About = () => {
	const { t } = useTranslation();

	return (
		<MainContainer>
			<motion.h1
				variants={fadeInTitleTopVariant}
				initial="hidden"
				whileInView="visible"
			>
				{t("about.title")}
			</motion.h1>

			<SectionAbout>
				<ArticleAbout>
					<Text>{t("about.intro")}</Text>
				</ArticleAbout>

				<ArticleAbout>
					<TitleText>{t("about.approach_title")}</TitleText>
					<Text>{t("about.approach_text")}</Text>
				</ArticleAbout>

				<ArticleAbout>
					<TitleText>{t("about.elegance_title")}</TitleText>
					<Text>{t("about.elegance_text")}</Text>
				</ArticleAbout>

				<ArticleAbout>
					<TitleText>{t("about.offer_title")}</TitleText>
					<ul>
						<ListText>{t("about.offer_text_one")}</ListText>
						<ListText>{t("about.offer_text_two")}</ListText>
						<ListText>{t("about.offer_text_three")}</ListText>
					</ul>
				</ArticleAbout>

				<ArticleAbout>
					<TitleText>{t("about.adventure_title")}</TitleText>
					<Text>{t("about.adventure_text")}</Text>
				</ArticleAbout>
			</SectionAbout>
		</MainContainer>
	);
};

export default About;

const MainContainer = styled.main`
	/* max-width: 85%; */
	padding: 6rem 0 5rem 0;
`;

const SectionAbout = styled.section`
	padding-top: 1rem;
	max-width: 85%;
	margin: auto;
	text-align: center;
`;

const ArticleAbout = styled.article`
	padding-bottom: 0.5rem;
	scale: 0.9;
	opacity: 0;
	animation: fade-in linear;
	animation-timeline: view();
	animation-range: contain;

	@keyframes fade-in {
		to {
			scale: 1;
			opacity: 1;
		}
	}
`;

const TitleAbout = styled.h1``;

const Text = styled.p`
	font-size: 1.2rem;
	color: #a8a29e;
`;

const TitleText = styled.h2`
	text-decoration: underline;
	text-transform: uppercase;
	font-size: 1.6rem;
`;

const ListText = styled.li`
	list-style-type: disc;
	color: #ffff;
	font-size: 1.2rem;
	color: #a8a29e;
`;
