import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";
import { styled } from "styled-components";
import { IoLogoGitlab } from "react-icons/io5";
import { IoLogoLinkedin } from "react-icons/io5";
import { useTranslation } from "react-i18next";

import Credits from "../screens/Credits";

const Footer = () => {
	const { t } = useTranslation();

	const [isOpen, setIsOpen] = useState(false);
	const linkRef = useRef(null);

	const handleKeyDown = (e) => {
		if (e.key === "Enter") {
			setIsOpen(true);
		}
	};

	return (
		<FooterContainer>
			<HR />
			<SectionContainer>
				<SubSection>
					<ArticleMentions>
						<MentionsUl>
							<MentionsLi>
								<Link to="/mentions">{t("footer.legal")}</Link>
							</MentionsLi>
							<MentionsLi>
								<SpanContain>|</SpanContain>
							</MentionsLi>
							<MentionsLi>
								<p
									tabIndex={0}
									ref={linkRef}
									onClick={() => setIsOpen(true)}
									onKeyDown={handleKeyDown}
								>
									{t("footer.credits")}
								</p>
								{isOpen && (
									<Credits
										openModal={isOpen}
										closeModal={() => setIsOpen(false)}
									/>
								)}
							</MentionsLi>
						</MentionsUl>
					</ArticleMentions>

					<ArticleCopy>
						<TextCopy>
							&copy; Copyright 2020-2024 - WebSculpt Creations
						</TextCopy>
					</ArticleCopy>
				</SubSection>

				<ArticleSocial className="social-links">
					<ArticleLink
						to="https://github.com/Johane4"
						rel="noopener noreferrer"
						target="_blank"
						aria-label="second link"
					>
						<IoLogoGitlab aria-hidden="true" />
					</ArticleLink>

					<ArticleLink
						to="https://www.linkedin.com/in/johane-o-07345835/"
						rel="noopener noreferrer"
						target="_blank"
						aria-label="first link"
					>
						<IoLogoLinkedin aria-hidden="true" />
					</ArticleLink>
				</ArticleSocial>
			</SectionContainer>
		</FooterContainer>
	);
};

export default Footer;

const FooterContainer = styled.footer`
	/* height: 4rem; */
`;

const HR = styled.hr`
	width: 90%;
	opacity: 0.5;
`;

const SectionContainer = styled.section`
	/* display: flex; */
	/* width: 70%; */
	width: 90%;
	// essayer d'enlever ça
	margin: 0 auto;
	display: flex;
	align-items: center;
	justify-content: space-between;

	/* @media (min-width: 480px) and (max-width: 576px) {
		max-width: 100%;
		justify-content: space-between;
	}

	@media (min-width: 375px) and (max-width: 480px) {
		max-width: 100%;
		justify-content: space-evenly;
	} */

	@media (max-width: 768px) {
		/* width: 100%; */
		/* flex-direction: column; */
	}

	@media (min-width: 480px) and (max-width: 576px) {
		max-width: 100%;

		/* margin: auto; */
		/* justify-items: right; */
		justify-content: space-around;
	}

	@media (min-width: 375px) and (max-width: 480px) {
		max-width: 100%;
		margin: auto;

		/* justify-content: space-around; */
	}
`;

const SubSection = styled.div`
	display: flex;
	align-items: center;
	max-width: 55%;
	margin: auto;

	/* @media (max-width: 768px) {
		width: 100% !important;
	} */

	@media (min-width: 480px) and (max-width: 576px) {
		max-width: 100%;

		/* justify-content: space-between; */
	}

	@media (min-width: 375px) and (max-width: 480px) {
		max-width: 100%;

		/* justify-content: space-around; */
	}
`;

const ArticleMentions = styled.article`
	/* display: flex; */
	@media (min-width: 480px) and (max-width: 576px) {
		/* display: flex;
		flex-direction: column; */
		/* width: 47%; */
		/* margin: 0; */
		/* justify-content: space-between; */
	}

	@media (min-width: 375px) and (max-width: 480px) {
		display: flex;
		width: 40%;
		/* margin: auto; */

		/* justify-content: space-around; */
	}
`;

const MentionsUl = styled.ul`
	display: flex;
	align-items: center;
`;

const MentionsLi = styled.li`
	padding: 0.5rem;
	font-size: 0.8rem;
	font-family: "Protest Revolution", sans-serif;

	p {
		/* padding: 0.5rem; */
		font-size: 0.8rem;
		font-family: "Protest Revolution", sans-serif;
		cursor: pointer;
	}

	@media (max-width: 768px) {
		font-size: 0.6rem;

		p {
			/* padding: 0.5rem; */
			font-size: 0.6rem;
		}
	}
`;

const TextCopy = styled.p`
	font-size: 0.8rem;
	font-family: "Protest Revolution", sans-serif;

	@media (max-width: 768px) {
		font-size: 0.6rem;
	}
`;

const SpanContain = styled.span`
	/* padding: 0.5rem; */
	color: #fafafa;
`;

const ArticleCopy = styled.article`
	padding-left: 1.5rem;

	@media (min-width: 480px) and (max-width: 576px) {
		/* width: 47%; */
		padding-left: 1rem;
		/* margin: 0; */
		/* justify-content: space-between; */
	}

	@media (min-width: 375px) and (max-width: 480px) {
		/* width: 30%; */
		/* margin: auto; */
		padding-left: 1rem;
		/* justify-content: space-around; */
	}
`;

const ArticleSocial = styled.article`
	/* display: flex;
	justify-content: flex-end; */
	font-size: 1.3rem;
	/* margin-right: 0.5rem; */

	@media (max-width: 768px) {
		font-size: 1rem;
	}

	@media (min-width: 480px) and (max-width: 576px) {
		/* max-width: 100%;
		justify-content: space-between; */
		display: flex;
	}

	@media (min-width: 375px) and (max-width: 480px) {
		/* max-width: 100%;
		justify-content: space-around; */
		display: flex;
	}
`;

const ArticleLink = styled(Link)`
	padding: 0.3rem;
`;
