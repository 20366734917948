import { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./screens/Home";
import Works from "./screens/Works";
import WorkDetails from "./screens/WorkDetails";
import Services from "./screens/Services";
import About from "./screens/About";
import Contact from "./screens/Contact";
import Credits from "./screens/Credits";
import MentionsLegales from "./screens/MentionsLegales";

import "./App.css";
import PageNotFound from "./screens/NotFound";

const App = () => {
	// Matomo analytics
	useEffect(() => {
		let _mtm = (window._mtm = window._mtm || []);
		_mtm.push({ "mtm.startTime": new Date().getTime(), event: "mtm.Start" });
		let d = document,
			g = d.createElement("script"),
			s = d.getElementsByTagName("script")[0];
		g.async = true;
		g.src =
			"https://cdn.matomo.cloud/johaneomiscar.matomo.cloud/container_6jEL4kz7.js";
		s.parentNode.insertBefore(g, s);
	}, []);

	const location = useLocation();

	const changeBackground = () => {
		if (
			window.location.pathname === "/works" ||
			window.location.pathname === "/services" ||
			window.location.pathname === "/about" ||
			window.location.pathname === "/mentions"
		) {
			document.body.classList.remove("body");
			document.body.classList.add("bodyColor");
		} else {
			document.body.classList.remove("bodyColor");
			document.body.classList.add("body");
		}
	};

	changeBackground();

	return (
		<>
			{!window.location.pathname.includes("/work-details/project/") && (
				<Header />
			)}
			<Routes location={location} key={location.pathname}>
				<Route path="/" element={<Home />} />
				<Route path="/works" element={<Works />} />
				<Route
					path="/work-details/project/:projectId"
					element={<WorkDetails />}
				/>
				<Route path="/services" element={<Services />} />
				<Route path="/about" element={<About />} />
				<Route path="/contact" element={<Contact />} />
				<Route path="/mentions" element={<MentionsLegales />} />
				<Route path="/credits" element={<Credits />} />
				<Route path="*" element={<Home />} />
			</Routes>
			{!window.location.pathname.includes("/work-details/project/") && (
				<Footer />
			)}
		</>
	);
};

export default App;
