export const fadeInTopVariant = {
	hidden: {
		y: 50,
		opacity: 0,
	},
	visible: {
		y: 0,
		opacity: 1,
	},
};

export const fadeInTitleTopVariant = {
	hidden: {
		y: -50,
		opacity: 0,
	},
	visible: {
		y: 0,
		opacity: 1,

		transition: {
			type: "tween",
			duration: 2,
		},
	},
};

export const fadeInBottomVariant = {
	hidden: {
		y: -50,
		opacity: 0,
	},
	visible: {
		y: 0,
		opacity: 1,
	},
};

export const fadeInRightVariant = {
	hidden: {
		x: -50,
		opacity: 0,
	},
	visible: {
		x: 0,
		opacity: 1,
	},
};

export const fadeInLeftVariant = {
	hidden: {
		x: 50,
		opacity: 0,
	},
	visible: {
		x: 0,
		opacity: 1,
	},
};

// export const fadeInScaleVariant = {
// 	hidden: {
// 		opacity: 0,
// 		scale: 0.5,
// 	},
// 	visible: {
// 		opacity: 1,
// 		scale: 1,
// 	},
// };
